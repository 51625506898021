import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Intro = () => (
  <>
    <Section className="pb-5">
      <Container>
        <Row className="justify-content-center mb-5">
          <Col>
            <Title variant="quote" className="mb-2">
              Privacy Policy
            </Title>
            <Text variant="small">
              Last updated: May 6, 2021
            </Text>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of
              Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </Text>
            <Text mt={3}>
              The California Consumer Privacy Act is effective as of January 1, 2020.
              California residents can learn more about their privacy
              rights <AnchorLink to="/privacy-app#CCPA"><u>Here</u></AnchorLink>.
            </Text>
            <Text mt={3}>
              We use Your Personal Data to provide and improve the Service.
              By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Intro;
